/* eslint-disable no-empty */
import axios from 'axios'
import * as crypto from 'crypto'

import { Forbidden, NotFoundError } from '@pff-consumer/api-common/lib/errors'
import type {
  GetGrootAuthorizeStateParams,
  GetGrootAuthorizeStateResponse,
  GetGrootMeParams,
  GetGrootMeResponse,
  UpdateGrootMeParams,
  UpdateGrootMeResponse,
  GetGrootAccessTokenParams,
  GetGrootAccessTokenResponse,
  GrootTokenParams,
  GrootClientToken,
  GrootJWT,
} from './types'
import { GetGrootRefreshTokenParams, GetGrootRefreshTokenResponse } from './types/get-groot-refresh-token'

interface GrootClientParams {
  grootUrl: string
  grootClientId: string
  grootClientSecret: string
  consumerGrootKey?: string
}

export class GrootClient {
  private grootUrl: string

  private grootClientId: string

  private grootClientSecret: string

  private consumerGrootKey: string | undefined

  constructor(params: GrootClientParams) {
    const { grootUrl, grootClientId, grootClientSecret, consumerGrootKey } = params
    if (!grootUrl) {
      throw new Error('Error initializing GrootClient grootUrl is required')
    }
    if (!grootClientId) {
      throw new Error('Error initializing GrootClient grootClientId is required')
    }
    if (!grootClientSecret) {
      throw new Error('Error initializing GrootClient grootClientSecret is required')
    }

    // Unfortunately many places in our existing codebase pass the env var as the base domain without the protocol
    // but we need to have the protocol available for the axios calls to work properly so I decided to fix here
    // instead of passing multiple versions fo the same env var
    if (grootUrl.includes('https://') || grootUrl.includes('http://')) {
      this.grootUrl = grootUrl
    } else {
      this.grootUrl = `https://${grootUrl}`
    }
    this.grootClientId = grootClientId
    this.grootClientSecret = grootClientSecret
    this.consumerGrootKey = consumerGrootKey
  }

  public getGrootAuthorizeState({ redirectUrl }: GetGrootAuthorizeStateParams): GetGrootAuthorizeStateResponse {
    const state = crypto.randomBytes(20).toString('hex')
    const encodedRedirectUrl = encodeURIComponent(redirectUrl)
    const url = `${this.grootUrl}/v2/oauth/authorize?client_id=${this.grootClientId}&redirect_uri=${encodedRedirectUrl}&response_type=code&state=${state}`
    return {
      state,
      url,
    }
  }

  public async getGrootMe({ accessToken }: GetGrootMeParams): Promise<GetGrootMeResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const url = `${this.grootUrl}/api/v1/users/me`
    try {
      const { data } = await axios.get<GetGrootMeResponse>(url, config)
      return data
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (Number(e.response?.status) === 403) {
          throw new Forbidden(`403 response from /api/v1/users/me`, e)
        }
        if (Number(e.response?.status) === 404) {
          throw new NotFoundError(`404 response from /api/v1/users/me`, e)
        }
      }
      throw e
    }
  }

  public async getGrootMeWithJWT({ jwt }: { jwt: string }): Promise<GetGrootMeResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }

    const url = `${this.grootUrl}/api/consumer/v2/user/me?client_id=${this.grootClientId}&client_secret=${this.grootClientSecret}`

    try {
      const { data } = await axios.get<GetGrootMeResponse>(url, config)
      return data
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (Number(e.response?.status) === 403) {
          throw new Forbidden(`403 response from /api/consumer/v2/user/me`, e)
        }
        if (Number(e.response?.status) === 404) {
          throw new NotFoundError(`404 response from /api/consumer/v2/user/me`, e)
        }
      }
      throw e
    }
  }

  public async refreshJWT({ jwt }: { jwt: string }): Promise<GrootJWT> {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-pff-source-app': 'consumer-api',
        Authorization: `Bearer ${jwt}`,
      },
    }

    const url = `${this.grootUrl}/api/consumer/v2/token/refresh?client_id=${this.grootClientId}&client_secret=${this.grootClientSecret}`

    const { data } = await axios.get<GrootJWT>(url, config)
    return data
  }

  public async updateGrootMe({ accessToken, updateParams }: UpdateGrootMeParams): Promise<UpdateGrootMeResponse> {
    const url = `${this.grootUrl}/api/v1/users/me?client_id=${this.grootClientId}&client_secret=${this.grootClientSecret}`
    const requestBody = {
      user: {
        current_password: updateParams.currentPassword,
        password: updateParams.password,
        password_confirmation: updateParams.password,
        first_name: updateParams.firstName,
        last_name: updateParams.lastName,
      },
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const { data } = await axios.put<UpdateGrootMeResponse>(url, requestBody, config)
    return data
  }

  public async getGrootAccessToken({
    code,
    redirectUrl,
  }: GetGrootAccessTokenParams): Promise<GetGrootAccessTokenResponse> {
    const encodedRedirectUrl = encodeURIComponent(redirectUrl)
    const url = `${this.grootUrl}/v2/oauth/token?client_id=${this.grootClientId}&client_secret=${this.grootClientSecret}&grant_type=authorization_code&redirect_uri=${encodedRedirectUrl}&code=${code}`
    const { data } = await axios.post<GetGrootAccessTokenResponse>(url)
    return data
  }

  public async getGrootJWT({ email, password }: { email: string; password: string }): Promise<GrootJWT> {
    const requestBody = {
      email,
      password,
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-pff-source-app': 'consumer-api',
      },
    }

    const url = `${this.grootUrl}/api/consumer/v2/token?client_id=${this.grootClientId}&client_secret=${this.grootClientSecret}`

    const { data } = await axios.post<GrootJWT>(url, requestBody, config)
    return data
  }

  public async getGrootRefreshToken({ accessToken, refreshToken }: GetGrootRefreshTokenParams) {
    const url = `${this.grootUrl}/v2/oauth/token?grant_type=refresh_token&client_id=${this.grootClientId}&client_secret=${this.grootClientSecret}`
    const requestBody = {
      refresh_token: refreshToken,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Key': accessToken,
        'x-pff-source-app': 'consumer-api',
      },
    }
    const { data } = await axios.post<GetGrootRefreshTokenResponse>(url, requestBody, config)
    return data
  }

  /*
    Will create a user with an email and password
  */
  public async createUserWithEmail({
    email,
    password,
  }: GrootTokenParams): Promise<{ success: boolean; error: string | null }> {
    const tok = await this.getClientToken()

    const url = `${this.grootUrl}/api/v1/users/me?client_id=${this.grootClientId}&client_secret=${this.grootClientSecret}`

    const requestBody = {
      email,
      password,
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${tok.access_token}`,
        'x-pff-source-app': 'consumer-groot-client',
      },
    }

    try {
      const { data } = await axios.post<{ success: boolean }>(url, requestBody, config)
      return {
        ...data,
        error: null,
      }
    } catch (e: any) {
      return {
        success: false,
        error: e.response.data.error.email[0],
      }
    }
  }

  /*
    This is needed to make calls to Groot. It uses client credentials based on an API user
    generated in groot. In order for this call to work, there needs to be an API key  created and
    passed to the groot client.

  */
  public async getClientToken(): Promise<GrootClientToken> {
    if (!this.consumerGrootKey) {
      throw new Error('Consumer Groot key is needed to make this request')
    }

    const url = `${this.grootUrl}/v2/oauth/token?grant_type=client_credentials&client_id=${this.grootClientId}&client_secret=${this.grootClientSecret}`

    const config = {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${this.consumerGrootKey}`,
        'x-pff-source-app': 'consumer-groot-client',
      },
    }

    const { data } = await axios.post<GrootClientToken>(url, {}, config)
    return data
  }

  /*
    This will mint a user token based on credentials
  */
  public async getUserToken({ email, password }: GrootTokenParams): Promise<GetGrootAccessTokenResponse> {
    const tok = await this.getClientToken()

    const requestBody = {
      username: email,
      password,
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${tok.access_token}`,
        'x-pff-source-app': 'consumer-groot-client',
      },
    }

    const url = `${this.grootUrl}/v2/oauth/token?grant_type=password&client_id=${this.grootClientId}&client_secret=${this.grootClientSecret}`
    const { data } = await axios.post<GetGrootAccessTokenResponse>(url, requestBody, config)
    return data
  }

  public async logoutGroot(): Promise<void> {
    const tok = await this.getClientToken()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${tok.access_token}`,
        'x-pff-source-app': 'consumer-groot-client',
      },
    }

    const url = `${this.grootUrl}/sso/logout?grant_type=password&client_id=${this.grootClientId}&client_secret=${this.grootClientSecret}`

    await axios.get<GetGrootAccessTokenResponse>(url, config)
  }
}
