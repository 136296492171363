import { GrootClient } from '@pff-consumer/groot-client'

export const LEGACY_CHECKOUT_URL = `${process.env.COSMOS_URL}/signup?referrer=subscribe-app`
export const OAUTH_REDIRECT_URL = `${process.env.SUBSCRIBE_URL}/oauth`
export const LEGACY_SUBSCRIPTIONS_URL = `${process.env.COSMOS_URL}/subscriptions`

if (!process.env.COSMOS_URL) {
  throw new Error('Error reading env variable, COSMOS_URL is required')
}
if (!process.env.SUBSCRIBE_URL) {
  throw new Error('Error reading env variable, SUBSCRIBE_URL is required')
}

export const grootClient = new GrootClient({
  grootUrl: process.env.GROOT_URL!,
  grootClientId: process.env.GROOT_CLIENT_ID!,
  grootClientSecret: process.env.GROOT_CLIENT_SECRET!,
})

export const redirectDomain = process.env.AWS_ENVIRONMENT === 'prod' ? 'www.pff' : 'www.pffstaging'
export const cookieDomain = process.env.AWS_ENVIRONMENT === 'prod' ? '.pff.com' : '.pffstaging.com'
export const COOKIE_AGE = 60 * 60 * 24 * 7

export const Routes = {
  Login: '/login',
  CreateAccount: '/create-account',
  AutoLogin: '/auto-login',
  CancelConfirmation: '/cancel/confirmation',
  CancelError: '/cancel/error',
}
