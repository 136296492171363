import type { NextPageContext } from 'next'
import * as Sentry from '@sentry/nextjs'
import { includes } from 'lodash'
import { getCookies } from 'cookies-next'
import Script from 'next/script'
import { FC, memo } from 'react'
import { grootClient } from '../../shared/constants'

interface GrootUser {
  uid: string
  email: string
  roles: string[]
  vertical: string
}

export interface GoogleDataProps {
  internalTraffic: 'Yes' | 'No'
  loggedInStatus: boolean
  subscriptionType: 'premium' | ''
  userId: string
}

export interface GoogleTagManagerHeadProps {
  dataLayer: GoogleDataProps
}

const CONSUMER_PREMIUM_ROLE = 'consumer-premium'
// eslint-disable-next-line @typescript-eslint/dot-notation, prefer-destructuring
const GOOGLE_TAG_MANAGER_ID = process.env['GOOGLE_TAG_MANAGER_ID']

if (!GOOGLE_TAG_MANAGER_ID) {
  throw new Error('MISSING: GOOGLE_TAG_MANAGER_ID ')
}

/**
 * This needs added to every page. There _should_ be an easier way to implement this
 * but for now there are only two routes.
 */
export const GoogleTagManagerHead: FC<GoogleTagManagerHeadProps> = memo((props: GoogleTagManagerHeadProps) => {
  const { dataLayer } = props
  const { internalTraffic, loggedInStatus, subscriptionType, userId } = dataLayer

  return (
    <Script
      id='subscribe-google-tag-manager-head'
      strategy='afterInteractive'
    >
      {`

      window.dataLayer = [
        {
          internalTraffic: '${internalTraffic}',
          loggedInStatus: '${loggedInStatus}',
          subscriptionType: '${subscriptionType}',
          userId: '${userId}',
        },
      ];

      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');

      `}
    </Script>
  )
})

export const GoogleTagManagerBody: FC = memo(() => {
  return (
    <Script
      id='subscribe-google-tag-manager-head'
      strategy='afterInteractive'
    >
      {`
      <!-- Google Tag Manager (noscript) -->
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <!-- End Google Tag Manager (noscript) -->

      `}
    </Script>
  )
})

// Checks if an email ends in @pff.com
const isPffEmail = (email?: string) => /^[a-zA-Z0-9._%+-]+@pff\.com$/.test(email || '')

/**
 * This returns yes/no strings instead of a boolean because
 * that is how it is configured in GTM and all of the other
 * legacy *.pff.com sites.
 */
const getInternalTraffic = (user?: GrootUser): 'Yes' | 'No' => {
  if (isPffEmail(user?.email) || user?.vertical?.toLowerCase() === 'pff') {
    return 'Yes'
  }

  return 'No'
}

// If there isn't a user, they aren't logged in
const getLoggedInStatus = (user?: GrootUser): boolean => user != null

/**
 * This returns 'premium' or empty '' because
 * that is how it is configured in GTM
 * and all of the other legacy *.pff.com sites.
 */
const getSubscriptionType = (user?: GrootUser) => {
  if (includes(user?.roles, CONSUMER_PREMIUM_ROLE)) {
    return 'premium'
  }

  return ''
}

/**
 * Makes the props that GoogleTagManagerHead expects
 */
const generateInitialDataLayerData = (user?: GrootUser): GoogleDataProps => {
  return {
    internalTraffic: getInternalTraffic(user),
    loggedInStatus: getLoggedInStatus(user),
    subscriptionType: getSubscriptionType(user),
    userId: user?.uid || '',
  }
}

/**
 * This takes a user and then will return the props needed to initialize the dataLayer
 * inside of GoogleTagManagerHead component.
 */
export const getServerSidePropsForGoogleTagManager = async (context: NextPageContext, grootUser?: GrootUser) => {
  let user: GrootUser | undefined
  try {
    const { c_groot_access_token: grootAccessToken, subscribe_access_token: subscribeAccessToken } = getCookies(context)
    const accessToken = subscribeAccessToken || grootAccessToken

    if (accessToken && typeof accessToken === 'string') {
      user = grootUser || (await grootClient.getGrootMe({ accessToken })) // TODO: We probably should pass in some of these values from the same cookies we use for Auth
    }
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
  }

  return {
    dataLayer: generateInitialDataLayerData(user),
  }
}
